import RootComponent from './router';

function App() {
  return (
    <div className="App">
      <RootComponent/>
    </div>
  );
}

export default App;
